var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _vm._e(),
      _vm._v(" ## How vue handle reactivity "),
      _c("ASuccessWithoutReload", {
        attrs: { api: this.api },
        on: { close: _vm.closeSuccessModal },
      }),
      !_vm.api.isLoading
        ? _c("v-data-table", {
            attrs: {
              items: _vm.data,
              search: _vm.search,
              headers: _vm.dataHeader,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "top",
                  fn: function () {
                    return [
                      _c(
                        "v-toolbar",
                        {
                          staticClass: "text-h4 pt-4",
                          attrs: { flat: "", height: "auto" },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mt-3 mx-3" },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "mb-3" },
                                    [
                                      _c(
                                        "v-toolbar-title",
                                        {
                                          staticClass:
                                            "font-weight-bold text-h4 my-3",
                                        },
                                        [
                                          _vm._v(
                                            " This is the title for the table "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          dense: "",
                                          outlined: "",
                                          label: "Search",
                                        },
                                        model: {
                                          value: _vm.search,
                                          callback: function ($$v) {
                                            _vm.search = $$v
                                          },
                                          expression: "search",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              55087503
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }